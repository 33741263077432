var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.licenseStatus
    ? _c("WATCHLISTUI", {
        ref: "UI",
        attrs: {
          skin: _vm.skin,
          showLoading: _vm.showLoading,
          dataModel: _vm.dataModel,
          symbol: _vm.symbolStr,
          removable: _vm.removable,
          activeSymbol: _vm.activeSymbol,
          settings: _vm.settings,
          labels: _vm.initedLabels,
          formatter: _vm.formatter,
          userPermission: _vm.userPermission
        },
        on: {
          track: _vm.trackEvent,
          "column-change": _vm.changeColumns,
          "export-rtd-click": _vm.exportRTDClick,
          "hover-menu-click": _vm.clickHoverMenu,
          "render-complete": _vm.renderComplete,
          "settings-changed": _vm.changeSettings,
          "settings-popover-hide": _vm.hideSettingsPopover,
          "symbol-update": _vm.updateSymbols,
          "row-double-click": _vm.doubleClick,
          "export-click": _vm.exportClick,
          "export-popover-hide": _vm.hideExportPopover
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }