import { hasPermission } from './columnSets.js';
export const DataPoints = [
    {
        id: 'name',
        minWidth: 150,
        maxWidth: 400,
        dataType: 'tree',
        width: 220,
        category: 'general',
        titleable: true
    },
    {
        id: 'dataType',
        align: 'right',
        category: 'general',
        width: 80
    },
    {
        id: 'symbol',
        align: 'right',
        titleable: true,
        dataType: 'string',
        category: 'general'
    },
    {
        id: 'upDown',
        align: 'right',
        dataType: 'upDownWithIcon',
        category: 'general'
    },
    {
        id: 'currency',
        align: 'right',
        category: 'general'
    },
    {
        id: 'region',
        align: 'right',
        category: 'general'
    },
    {
        id: 'sector',
        align: 'right',
        category: 'general'
    },
    {
        id: 'industry',
        align: 'right',
        category: 'general',
        minWidth: 150,
        maxWidth: 300,
        width: 220
    },
    {
        id: 'marketCap',
        align: 'right',
        category: 'general',
        dataType: 'number',
        width: 80
    },
    {
        id: 'marketCapAsOfDate',
        align: 'right',
        dataType: 'date',
        category: 'general',
        width: 80
    },
    {
        id: 'fiscalYearEndMonth',
        labelKey: 'fiscalYearEndMonthColumn',
        align: 'right',
        dataType: 'date',
        category: 'general'
    },
    {
        id: 'lastPrice',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'prePostPrice',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'closePrice',
        labelKey: 'closePriceColumn',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'middlePrice',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'strikePrice',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'openInterest',
        align: 'right',
        category: 'mktdata'
    },
    {
        id: 'spread',
        align: 'right',
        dataType: 'upDown',
        category: 'mktdata'
    },
    {
        id: 'extraExchangeInfo',
        align: 'right',
        dataType: 'date',
        category: 'mktdata'
    },
    {
        id: 'chg',
        align: 'right',
        flash: 'color',
        dataType: 'upDown',
        category: 'mktdata'
    },
    {
        id: 'chg%',
        align: 'right',
        flash: 'color',
        dataType: 'upDownWithPercent',
        category: 'mktdata'
    },
    {
        id: 'openPrice',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'prePostChg',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'prePostChg%',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'nav',
        align: 'right',
        flash: 'color',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'bidPrice',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'bidSize',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'askPrice',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'askSize',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'avgVolume2WKDaily',
        align: 'right',
        minWidth: 80,
        maxWidth: 150,
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'dividendYieldCurrent',
        align: 'right',
        minWidth: 80,
        maxWidth: 150,
        dataType: 'numberWithPercent',
        category: 'fundamentals'
    },
    {
        id: 'volume',
        align: 'right',
        minWidth: 80,
        maxWidth: 150,
        dataType: 'int',
        category: 'mktdata'
    },
    {
        id: 'VWAP',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'week52HighPrice',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'week52LowPrice',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'week52HighDate',
        align: 'right',
        dataType: 'date',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'week52LowDate',
        align: 'right',
        dataType: 'date',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'highPrice',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'lowPrice',
        align: 'right',
        dataType: 'number',
        category: 'mktdata'
    },
    {
        id: 'tradeDate',
        align: 'right',
        dataType: 'date',
        category: 'mktdata'
    },
    {
        id: 'prePostDate',
        align: 'right',
        dataType: 'date',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'tradeTime',
        align: 'right',
        dataType: 'time',
        category: 'mktdata'
    },
    {
        id: 'time',
        align: 'right',
        dataType: 'time',
        category: 'mktdata'
    },
    {
        id: 'prePostTime',
        align: 'right',
        dataType: 'time',
        category: 'mktdata',
        width: 80
    },
    {
        id: 'timezoneAbbreviation',
        align: 'right',
        dataType: 'string',
        category: 'mktdata'
    },
    {
        id: 'returnEndDate',
        align: 'right',
        dataType: 'date',
        category: 'returns',
        width: 80
    },
    {
        id: 'ytdReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'oneDayReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'oneWeekReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'oneMonthReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'threeMonthReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'sixMonthReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'oneYearReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'twoYearReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'threeYearReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'fiveYearReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'tenYearReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'fifteenYearReturn',
        align: 'right',
        dataType: 'upDown',
        category: 'returns'
    },
    {
        id: 'forwardPERatio',
        align: 'right',
        dataType: 'number',
        category: 'fundamentals'
    },
    {
        id: 'PE',
        align: 'right',
        dataType: 'number',
        category: 'fundamentals'
    },
    {
        id: 'EPSTTM',
        align: 'right',
        dataType: 'number',
        category: 'fundamentals'
    },
    {
        id: 'PB',
        align: 'right',
        dataType: 'number',
        category: 'fundamentals'
    },
    {
        id: 'lastDividend',
        align: 'right',
        dataType: 'number',
        category: 'fundamentals'
    },
    {
        id: 'forwardDividendYield',
        align: 'right',
        dataType: 'number',
        category: 'fundamentals'
    },
    {
        id: 'lastDividendDate',
        align: 'right',
        dataType: 'date',
        category: 'fundamentals'
    },
    {
        id: 'MSRating',
        align: 'right',
        width: 80,
        dataType: 'starRating',
        category: 'msrate'
    },
    {
        id: 'fairValueEstimate',
        align: 'right',
        category: 'msrate',
        dataType: 'number'
    },
    {
        id: 'FVUncertainty',
        align: 'right',
        width: 80,
        category: 'msrate'
    },
    {
        id: 'economicMoat',
        labelKey: 'economicMoatAbbr',
        align: 'right',
        category: 'msrate'
    },
    {
        id: 'stewardShip',
        align: 'right',
        category: 'msrate'
    },
    {
        id: 'MSStyleBox',
        align: 'right',
        width: 85,
        category: 'msrate',
        dataType: 'styleBox'
    },
    {
        id: 'expirationDate',
        align: 'right',
        width: 80,
        dataType: 'date',
        category: 'mktdata'
    },
    {
        id: 'lastUpdateTime',
        align: 'right',
        dataType: 'time',
        category: 'mktdata'
    },
    {
        id: 'usEquityComposition',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'nonUSEquityComposition',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'fixedIncomeComposition',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'otherComposition',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'cashComposition',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'equityRegionNotClassifiedPercentage',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'northAmericaPercentage',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'unitedKingdomPercentage',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'europeDevelopedPercentage',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'europeEmergingPercentage',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'africaMiddleEastPercentage',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'japanPercentage',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'australasiaPercentage',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'asiaDevelopedPercentage',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'asiaEmergingPercentage',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'latinAmericaPercentage',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'portfolioDate',
        align: 'right',
        dataType: 'date',
        width: 80
    },
    {
        id: 'effectiveDuration',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'effectiveMaturity',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'averageCreditQualityName',
        align: 'left',
        width: 80,
        dataType: 'string'
    },
    {
        id: 'creditQualityAaa',
        align: 'right',
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityAa',
        align: 'right',
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityA',
        align: 'right',
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityBbb',
        align: 'right',
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityBb',
        align: 'right',
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityB',
        align: 'right',
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityBelowB',
        align: 'right',
        width: 80,
        dataType: 'numberWithPercent'
    },
    {
        id: 'creditQualityNotRated',
        align: 'right',
        width: 80,
        dataType: 'numberWithPercent'
    },
    {
        id: 'government',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'corporate',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'securitized',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'municipal',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'cashAndEquivalents',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'derivative',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'snpSubIndustryName',
        align: 'left',
        license: ['GICS'],
        dataType: 'string',
        width: 80,
        category: 'gicsStockClassification'
    },
    {
        id: 'snpIndustryName',
        align: 'left',
        license: ['GICS'],
        dataType: 'string',
        width: 80,
        category: 'gicsStockClassification'
    },
    {
        id: 'snpIndustryGroupName',
        dataType: 'string',
        align: 'left',
        license: ['GICS'],
        width: 80,
        category: 'gicsStockClassification'
    },
    {
        id: 'snpSectorName',
        align: 'left',
        license: ['GICS'],
        dataType: 'string',
        width: 80,
        category: 'gicsStockClassification'
    },
    {
        id: 'upsideCaptureRatioFor1Year',
        align: 'right',
        width: 80,
        dataType: 'number',
        category: 'fundData'
    },
    {
        id: 'downsideCaptureRatioFor1Year',
        align: 'right',
        width: 80,
        dataType: 'number',
        category: 'fundData'
    },
    {
        id: 'sharpeRatioFor3Year',
        align: 'right',
        width: 80,
        dataType: 'number',
        category: 'fundData'
    },
    {
        id: 'turnover',
        align: 'right',
        dataType: 'number',
        category: 'fundData'
    },
    {
        id: 'ttMYield',
        align: 'right',
        dataType: 'numberWithPercent',
        category: 'fundData'
    },
    {
        id: 'secYield',
        align: 'right',
        dataType: 'numberWithPercent',
        category: 'fundData'
    },
    {
        id: 'basicMaterials',
        labelKey: 'basicMaterials%',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'consumerCyclical',
        labelKey: 'consumerCyclical%',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'financialServices',
        labelKey: 'financialServices%',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'realEstate',
        labelKey: 'realEstate%',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'communicationServices',
        labelKey: 'communicationServices%',
        showPercentSign: true,
        align: 'right',
        width: 90,
        dataType: 'number'
    },
    {
        id: 'energy',
        labelKey: 'energy%',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'industrials',
        labelKey: 'industrials%',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'technology',
        labelKey: 'technology%',
        align: 'right',
        width: 80,
        dataType: 'number'
    },
    {
        id: 'consumerDefensive',
        labelKey: 'consumerDefensive%',
        align: 'right',
        width: 85,
        dataType: 'number'
    },
    {
        id: 'healthcare',
        labelKey: 'healthcare%',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'utilities',
        labelKey: 'utilities%',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'gicsEnergy',
        align: 'right',
        width: 85,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsMaterials',
        align: 'right',
        width: 85,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsIndustrials',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsConsumerDiscretionary',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsConsumerStaples',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsHealthcare',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsFinancials',
        align: 'right',
        width: 85,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsInformationTechnology',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsCommunicationServices',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsUtilities',
        align: 'right',
        width: 85,
        license: ['GICS'],
        dataType: 'number'
    },
    {
        id: 'gicsRealEstate',
        align: 'right',
        width: 90,
        license: ['GICS'],
        dataType: 'number'
    }
];
export function getDataPointsByDataType(dataTypes = {}, allDataPoints) {
    const dps = [];
    allDataPoints.forEach(d => {
        if (d.dataType && dataTypes[d.dataType]) {
            dps.push(d.id);
        }
    });
    return dps;
}

export function checkDataPointLicense(dataPoint, Permission) {
    if (!dataPoint.license) {
        return true;
    } else {
        if (!Permission.length) {
            return false;
        } else {
            return dataPoint.license.some(f => hasPermission(Permission, f));
        }
    }
}

export function getDataPointByCategory(allDataPoints) {
    const map = {};
    allDataPoints.forEach(d => {
        if (d.category && !map[d.category]) {
            map[d.category] = [];
        }
        if (d.category) {
            map[d.category].push(d.id);
        }
    });
    return map;
}
