<template>
    <markets-ui-container
        :class="namespace()"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        :showExport="watchedSettings.showExport"
        @setting-click="toggleSettings"
        @export-click="toggleExport"
    >
        <div slot="content" :class="namespace('body')">
            <div
                v-if="watchedSettings.showExport || showWebLayout"
                role="navigation"
                aria-label="Component Settings"
                :class="[namespace('navigation-setting')]"
            >
                <div
                    v-if="showWebLayout"
                    :class="[namespace('navigation-setting-button-group')]"
                >
                    <div>
                        <markets-ui-menus
                            :dataModel="settingsNavGroupList"
                            :label="initedLabels.standard"
                            @change="changeSettingsNavGroup"
                            :skin="watchedSettings.skin"
                            :collapse-mode="menusCollapse"
                        ></markets-ui-menus>
                    </div>
                    <markets-ui-button
                        :class="namespace('button-custom')"
                        icon-right="caret-down"
                        :text="customButtonName"
                        variation="secondary"
                        :skin="watchedSettings.skin"
                        :aria-expanded="customExpanded"
                        @click="handleCustomDataPointsClick"
                    ></markets-ui-button>
                    <div
                        :class="namespace('group-custom')"
                        v-if="watchedSettings.showGroup"
                    >
                        <div :class="namespace('group-switch')">
                            <span>{{ initedLabels.groupView }}</span>
                            <markets-ui-switch
                                :skin="skin"
                                :checked="watchedSettings.enableGroup"
                                :label="initedLabels.groupView"
                                value="volume"
                                hiddenLabel
                                @change="switchGroupKey"
                            ></markets-ui-switch>
                        </div>
                        <span
                            :class="namespace('group-custom-text')"
                            v-if="watchedSettings.enableGroup"
                            >{{ initedLabels.by }}</span
                        >
                        <markets-ui-select
                            v-if="watchedSettings.enableGroup"
                            optional
                            :label="initedLabels.timePeriodUpperCase"
                            :skin="watchedSettings.skin"
                            :data-model="groupKeyList"
                            @change="changeGroupKey"
                        ></markets-ui-select>
                    </div>
                </div>

                <markets-ui-button
                    v-if="watchedSettings.showExport && showWebLayout"
                    :skin="skin"
                    icon="share"
                    icon-left="share"
                    :variation="isMobile ? 'icon-only' : 'flat'"
                    :text="initedLabels.exportButton"
                    @click.stop="exportData('excel', 'xls')"
                />
            </div>
            <div
                :class="[
                    namespace('grid-container'),
                    showWebLayout ? '' : namespace('grid-container__no-menu')
                ]"
            >
                <markets-ui-grid
                    :skin="watchedSettings.skin"
                    :group-key="groupKey"
                    :formatter="formatter"
                    :hover="watchedSettings.gridHover"
                    :option="gridOption"
                    :columns="gridColumns"
                    :rows="dataModel"
                    :focusRowId="activeSymbol"
                    :labels="gridLabels"
                    :caption="initedLabels.watchlistTitle"
                    @hover-menu-click="$emit('hover-menu-click', $event)"
                    @visible-rows-update="handleSubscribe"
                    @row-double-click="$emit('row-double-click', $event)"
                    @render-complete="$emit('render-complete', $event)"
                >
                </markets-ui-grid>
            </div>
        </div>
        <markets-ui-settings
            ref="watchlistUiSettings"
            slot="settings"
            :showSearchField="showSettingsPoints"
            :showDone="showDoneSettings"
            :showBack="showBackSettings"
            :showReset="showReset"
            :skin="watchedSettings.skin"
            :triggered-by="settingsPopoverTriggerId"
            :visible="popoverVisible"
            :title="popoverTitle"
            :doneLabel="doneLabel"
            :width="popoverWidth"
            :searchFieldPlaceholder="initedLabels.search"
            :doneDisable="!!infoMsg"
            :class="namespace('settings')"
            @hide="hideSettingsPopover"
            @reset="handleResetClick"
            @back="handleBackClick"
            @done="handleDoneClick"
            @search-key-changed="filterDataPoints"
        >
            <markets-ui-list
                slot="content"
                size="small"
                v-if="showColumnSetsList"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNavSelectedList"
                variation="selection"
                @change="changeSettingsNavSelected"
                @click="changeExportFileType"
            >
            </markets-ui-list>
            <template slot="content" v-if="watchedSettings.showGroup">
                <div :class="namespace('group-switch')">
                    <span v-if="isPopoverTypeSettings">{{
                        initedLabels.groupView
                    }}</span>
                    <markets-ui-switch
                        v-if="isPopoverTypeSettings"
                        :skin="skin"
                        :checked="watchedSettings.enableGroup"
                        :label="initedLabels.groupView"
                        value="volume"
                        hiddenLabel
                        @change="switchGroupKey"
                    ></markets-ui-switch>
                </div>
                <markets-ui-select
                    v-if="watchedSettings.enableGroup && isPopoverTypeSettings"
                    optional
                    :skin="watchedSettings.skin"
                    :label="initedLabels.timePeriodUpperCase"
                    :data-model="groupKeyList"
                    @change="changeGroupKey"
                ></markets-ui-select>
            </template>

            <template v-if="showSettingsPoints" slot="content">
                <div
                    v-for="item in settingDataPoints"
                    :key="item.id"
                    :class="namespace('settings-group-container')"
                >
                    <div
                        :class="namespace('content-header')"
                        v-if="item.showTitle"
                    >
                        {{ item.title }}
                    </div>
                    <markets-ui-checkbox-group
                        size="small"
                        :skin="watchedSettings.skin"
                        :dataModel="item.list"
                        @change="changeSettingDataPoints"
                    ></markets-ui-checkbox-group>
                </div>
            </template>
            <template v-if="showSettingsColumns" slot="content">
                <DRAGABLELIST
                    :dataModel="selectedDataPointsModel"
                    :skin="watchedSettings.skin"
                    :labels="dragableLabels"
                    @change="changePointsColumns"
                    slot="content"
                >
                </DRAGABLELIST>
            </template>
            <template v-if="exportDownLoadVisible" slot="content">
                <div :class="namespace('export-header')">
                    <div>{{ initedLabels.exportRtdLabel }}</div>
                    <div
                        v-html="exportInfoLabel"
                        @click="exportRTDClick($event)"
                    ></div>
                </div>
            </template>
            <footer slot="footer" :class="namespace('container-footer')">
                <div v-if="showSettingsPoints">
                    <div :class="[namespace('content__error')]" v-if="infoMsg">
                        {{ infoMsg }}
                    </div>
                    <div :class="namespace('content__panel')">
                        <span
                            :class="[
                                namespace('content__count'),
                                isGreaterMaxCount
                                    ? namespace('content__greater')
                                    : ''
                            ]"
                            >{{ dataPointCount }}</span
                        >
                        <markets-ui-button
                            :class="namespace('content__reset')"
                            variation="flat"
                            :skin="watchedSettings.skin"
                            :text="initedLabels.deselect"
                            @click.stop="deselectAllClick"
                        />
                    </div>
                </div>
                <div v-if="showSettingsColumns">
                    <div :class="[namespace('content__msg-info')]">
                        {{ initedLabels.dragInfoMsg }}
                    </div>
                </div>
            </footer>
        </markets-ui-settings>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import {
    DataPoints,
    getDataPointByCategory,
    checkDataPointLicense
} from './metadata/dataPoint';
import DRAGABLELIST from '@core/src/ui/dragable-list/dragable-list';
import {
    getDataPoint,
    DEFAULT_COLUMN_SET,
    getColumnSetsByLicense,
    COLUMN_SET_TYPES,
    COLUMN_SET_BEHAVIOUR,
    mergeColumnSets
} from './metadata/columnSets';

const { utils, mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-watchlist-ui',
    mixins: [mixins.componentUI],
    components: {
        DRAGABLELIST
    },
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        symbol: {
            type: String,
            default: ''
        },
        removable: {
            type: Boolean
        },
        activeSymbol: {
            type: String
        },
        settings: {
            type: Object
        },
        labels: {
            type: Object
        },
        formatter: {
            type: Object
        },
        userPermission: {
            type: Array
        }
    },
    watch: {
        symbol() {
            this.changeSettings(this._getStoreSettings());
        },
        removable(val) {
            this.watchedSettings.removable = val;
            if (
                this.watchedSettings.gridHover &&
                typeof this.watchedSettings.gridHover.removable === 'boolean'
            ) {
                this.watchedSettings.gridHover.removable = val;
            }
        },
        popoverVisible(val) {
            if (!val) {
                this.showSettingsPoints = false;
                this.showSettingsColumns = false;
                this.exportDownLoadVisible = false;
            }
        },
        columnType(value) {
            if (value) {
                // Tracking: number of usage on each view (Index, Returns, Fundamental, Custom).
                this.trackEvent({
                    name: 'column-type-change',
                    details: {
                        action: 'changed',
                        value
                    }
                });
            }
        }
    },
    data() {
        return {
            settingsPopoverVisible: false,
            settingsPopoverTriggerId: null,
            settingsPopoverNavSelected: undefined,
            exportPopoverVisible: false,
            exportPopoverSelected: false,
            exportDownLoadVisible: false,
            popoverType: '',
            settingDataPoints: [],
            showSettingsPoints: false,
            showSettingsColumns: false,
            filterKey: '', //popover filter key
            selectedDataPointsModel: [],
            groupKey: null, //for grid groupKey: 'region,sector,category'
            groupKeyId: null, // for save groupKey id: region | regionSector
            dataPoints: [],
            columnType: ''
        };
    },
    computed: {
        infoMsg() {
            if (this.selectedDataPointsModel.length === 0) {
                return this.initedLabels.noPointSelected;
            } else if (this.isGreaterMaxCount) {
                return utils.stringFormat(this.initedLabels.reachMsg, {
                    count: `${this.watchedSettings.maxCount}`
                });
            }
            return '';
        },
        showReset() {
            return (
                this.showSettingsPoints &&
                !!this.watchedSettings.defaultDataPoints
            );
        },
        exportInfoLabel() {
            const link = `<span>${this.initedLabels.here}</span>`;
            return utils.stringFormat(this.initedLabels.exportInfoLabel, {
                link: link
            });
        },
        doneLabel() {
            return this.showSettingsPoints
                ? this.initedLabels.labelOk
                : this.initedLabels.labelDone;
        },
        showColumnSetsList() {
            return (
                this.settingsPopoverNavSelected ||
                this.exportPopoverSelected ||
                this.exportDownLoadVisible
            );
        },
        groupKeyList() {
            return this.groupDataPoints.map(item => {
                return {
                    id: item.id,
                    name: item.label || this.initedLabels[item.name || item.id],
                    selected: item.id === this.groupKeyId
                };
            });
        },
        gridLabels() {
            const labels = {};
            [
                'previousPage',
                'previousColumn',
                'nextPage',
                'nextColumn'
            ].forEach(key => {
                labels[key] = this.initedLabels[key];
            });
            return labels;
        },
        gridOption() {
            const option = {};
            [
                'scrollbarSize',
                'removable',
                'focusable',
                'rowHeight',
                'autoHeight',
                'frozenColumn',
                'frozenRow',
                'layout',
                'rowsPerPage',
                'rowsPerPageList',
                'showPageInfo',
                'showPageSelect',
                'stickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[key] = this.watchedSettings[key];
                }
            });
            if (
                this.groupKey &&
                typeof this.watchedSettings.updateGroupFunc === 'function'
            ) {
                option.updateGroupFunc = this.watchedSettings.updateGroupFunc;
            }
            return option;
        },
        groupDataPoints() {
            const groupDataPoints = [
                { id: 'sector', points: ['sector'] },
                { id: 'industry', points: ['industry'] },
                {
                    id: 'extraExchangeInfo',
                    name: 'exchange',
                    points: ['tradeExchange']
                },
                { id: 'MSCategory', points: ['MSCategory'] }
            ];
            return [
                ...groupDataPoints,
                ...this.watchedSettings.customGroupDataPoints
            ];
        },
        popoverWidth() {
            let width = 200;
            if (this.exportDownLoadVisible) {
                width = 250;
            }
            return width;
        },
        dataPointCount() {
            const max = this.watchedSettings.maxCount;
            return `${this.selectedDataPointsModel.length}/${max}`;
        },
        columnSets() {
            let columnSets;
            if (this.watchedSettings.columnSets) {
                if (
                    this.watchedSettings.columnSetsBehaviour ===
                    COLUMN_SET_BEHAVIOUR.OVERRIDE
                ) {
                    columnSets = utils.extend(
                        true,
                        [],
                        this.watchedSettings.columnSets
                    );
                } else {
                    columnSets = mergeColumnSets(
                        this.watchedSettings.columnSets
                    );
                }
            } else {
                // if there is no columnSets passed from user, fallback to use default
                columnSets = utils.extend(true, [], DEFAULT_COLUMN_SET);
            }

            // filter default column sets with licenses
            columnSets = getColumnSetsByLicense(
                columnSets,
                this.userPermission
            );

            // add custom if it is not exist
            if (
                utils.inArray(
                    columnSets,
                    item => item.id === COLUMN_SET_TYPES.CUSTOM
                ) === -1
            ) {
                // saved dataPoints is string format
                columnSets.push({
                    id: COLUMN_SET_TYPES.CUSTOM,
                    dataPoints: this.dataPoints
                });
            }
            // reduce the duplicate and check data points licenses
            return columnSets.map(columnSet => {
                const unique = {};
                const dataPoints = [];
                columnSet.dataPoints.forEach(d => {
                    if (
                        !unique[d] &&
                        utils.inArray(
                            this.allDataPoints,
                            item => item.id === d
                        ) > -1
                    ) {
                        dataPoints.push(d);
                        unique[d] = true;
                    }
                });
                columnSet.dataPoints = dataPoints;
                return columnSet;
            });
        },
        isGreaterMaxCount() {
            return (
                this.selectedDataPointsModel.length >
                this.watchedSettings.maxCount
            );
        },
        isPopoverTypeSettings() {
            return (
                this.popoverType === 'settings' &&
                this.settingsPopoverNavSelected
            );
        },
        gridColumns() {
            const gridData = [];
            const columnArray = this.columnSets.filter(o => {
                return o.id === this.columnType;
            });
            columnArray[0].dataPoints.forEach(dp => {
                const target = utils.find(
                    this.allDataPoints,
                    item => item.id === dp
                );
                if (target) {
                    gridData.push(target);
                }
            });
            return gridData.map(f => {
                // Tracking: number of column set by user.
                this.trackEvent({
                    name: 'column-select',
                    value: f.id
                });

                return utils.extend(true, f, {
                    name: f.label || this.initedLabels[f.labelKey || f.id],
                    sortable: this.watchedSettings.sortable
                });
            });
        },
        showBackSettings() {
            if (this.showWebLayout) {
                return this.showSettingsColumns;
            } else {
                return (
                    this.showSettingsPoints ||
                    this.showSettingsColumns ||
                    this.exportDownLoadVisible
                );
            }
        },
        showDoneSettings() {
            return this.showSettingsPoints || this.showSettingsColumns;
        },
        allDataPoints() {
            return [
                ...DataPoints,
                ...this.watchedSettings.customColumns
            ].filter(f => checkDataPointLicense(f, this.userPermission));
        },
        popoverTitle() {
            let title = '';
            if (this.popoverType === 'export') {
                title = this.initedLabels.exportTitle;
                if (this.exportDownLoadVisible) {
                    title = this.initedLabels.exportSettingsRTD;
                }
            } else {
                title = this.initedLabels.settingTitle;
                if (this.showSettingsPoints) {
                    title = this.initedLabels.dataPoints;
                } else if (this.showSettingsColumns) {
                    title = this.initedLabels.columnsets;
                }
            }
            return title;
        },
        customButtonName() {
            const custom = utils.find(
                this.columnSets,
                o => o.id === COLUMN_SET_TYPES.CUSTOM
            );
            return this.initedLabels[custom.id];
        },
        isMobile() {
            return this.watchedSettings.width < 800;
        },
        settingsNavGroupList() {
            return this.columnSets
                .filter(o => o.id !== 'custom')
                .map(columnType => {
                    const selected = columnType.id === this.columnType;
                    return {
                        id: columnType.id,
                        name: this.initedLabels[columnType.id],
                        selected
                    };
                });
        },
        settingsPopoverNavSelectedList() {
            if (this.popoverType === 'export') {
                if (this.exportPopoverSelected) {
                    const ret = [
                        {
                            id: 'excel',
                            name: this.initedLabels.excel,
                            click: true
                        }
                    ];
                    if (this.watchedSettings.showExcelRTD) {
                        ret.push({
                            id: 'excelRtd',
                            name: this.initedLabels.excelTemplate,
                            isNavigation: true,
                            click: true
                        });
                    }
                    return ret;
                } else if (this.exportDownLoadVisible) {
                    return [
                        {
                            id: 'quote',
                            name: this.initedLabels.quote,
                            click: true
                        }
                    ];
                }
                return [];
            } else if (this.isPopoverTypeSettings) {
                return this.columnSets.map(columnType => {
                    if (columnType.id === COLUMN_SET_TYPES.CUSTOM) {
                        const count = this.gridColumns.length;
                        return {
                            id: columnType.id,
                            name: this.initedLabels[columnType.id],
                            isNavigation: true,
                            metadataBelow: `${count} ${this.initedLabels.selected}`
                        };
                    } else {
                        return {
                            id: columnType.id,
                            name: this.initedLabels[columnType.id],
                            selected: columnType.id === this.columnType
                        };
                    }
                });
            } else {
                return [];
            }
        },
        popoverVisible() {
            return this.settingsPopoverVisible || this.exportPopoverVisible;
        },
        dragableLabels() {
            return {
                remove: this.initedLabels.remove,
                grab: this.initedLabels.grab
            };
        },
        menusCollapse() {
            if (this.isMobile) {
                return 'collapse';
            } else {
                return 'extend';
            }
        },
        customExpanded() {
            return (
                '' + (this.showSettingsPoints && this.settingsPopoverVisible)
            );
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('watchlist');
        this.columnType = this.watchedSettings.columnType;
        const targetColumnType = utils.find(
            this.columnSets,
            columnType => this.columnType === columnType.id
        );
        if (!targetColumnType) {
            this.columnType = this.columnSets[0].id;
        }
        this.saveSelectPoints = {};
        if (
            this.watchedSettings.enableGroup &&
            (this.watchedSettings.showGroup ||
                this.watchedSettings.customRows.length > 0)
        ) {
            const { groupKey, groupKeyId } = this._getGroupKeyItem(
                this.watchedSettings.groupKey
            );
            this.groupKey = groupKey;
            this.groupKeyId = groupKeyId;
        }
        const maxCount = this.watchedSettings.maxCount;
        if (this.columnType === COLUMN_SET_TYPES.CUSTOM) {
            if (this.watchedSettings.dataPoints) {
                this.dataPoints = this.watchedSettings.dataPoints.split(',');
                if (this.dataPoints.length > maxCount) {
                    this.dataPoints.splice(0, maxCount);
                }
            } else {
                this.dataPoints = getDataPoint(COLUMN_SET_TYPES.BASIC);
            }
            this.dataPoints.forEach(point => {
                this.allDataPoints.forEach(dp => {
                    if (point === dp.id) {
                        this.saveSelectPoints[point] = true;
                    }
                });
            });
        }
        this.changeColumns({ id: this.columnType });
        this.sheetName = null;
    },
    methods: {
        _resizeObserverCallback({ entry }) {
            this.$set(this.watchedSettings, 'width', entry.contentRect.width);
            this.hideSettingsPopover();
        },
        toggleSettings(event) {
            this.popoverType = 'settings';
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverNavSelected = this.settingsPopoverVisible;
            this.exportPopoverSelected = false;
            this.exportPopoverVisible = false;
            this.settingsPopoverTriggerId = utils.computeTriggerId(
                event.currentTarget
            );
        },
        toggleExport(e, sheetName) {
            this.popoverType = 'export';
            this.sheetName = sheetName;
            this.settingsPopoverVisible = false;
            this.exportPopoverVisible = !this.exportPopoverVisible;
            this.exportPopoverSelected = !this.exportPopoverSelected;
            this.settingsPopoverTriggerId = utils.computeTriggerId(e.target);
        },
        exportData(fileType, suffix) {
            let columns;
            this.columnSets.forEach(item => {
                if (item.id === this.columnType) {
                    columns = item.dataPoints;
                }
            });
            const columnsLabel = columns.map(cl => {
                const dp = utils.find(
                    this.allDataPoints,
                    item => item.id === cl
                );
                return dp.label || this.initedLabels[dp.labelKey || dp.id];
            });

            const exportItem = {
                columnsLabel: columnsLabel.join(','),
                columns: columns.join(','),
                fileType: fileType,
                sheetName: this.sheetName || 'Watchlist',
                suffix
            };
            this.exportPopoverSelected = false;
            this.$emit('export-click', exportItem, this.formatter);
        },
        changeExportFileType(item) {
            if (item.id === 'excelRtd') {
                this.exportPopoverSelected = false;
                this.exportDownLoadVisible = true;
            } else {
                let suffix = 'xls';
                if (item.id === 'quote') {
                    suffix = 'xlsx';
                }
                this.exportData(item.id, suffix);
                this.exportPopoverVisible = false;
            }
        },
        changeSettingDataPoints(item) {
            const saveSelectPoints = utils.extend(this.saveSelectPoints, item);
            Object.keys(saveSelectPoints).forEach(item => {
                if (!saveSelectPoints[item]) {
                    delete saveSelectPoints[item];
                }
            });
            this.saveSelectPoints = saveSelectPoints;
            this.setDataPointsAndModel();
        },
        filterDataPoints(keyword) {
            this.filterKey = keyword;
            this.settingDataPoints = this._getSettingDataPoints();
        },
        _getSettingDataPoints() {
            const categoryData = getDataPointByCategory(this.allDataPoints);
            const data = [];
            Object.keys(categoryData).forEach(category => {
                const categoryObj = {
                    id: category,
                    name: this.initedLabels[category],
                    title: this.initedLabels[category]
                };
                const list = [];
                categoryData[category].forEach(item => {
                    const dataPointItem = utils.find(
                        this.allDataPoints,
                        dp => item === dp.id
                    );
                    const name =
                        dataPointItem.label ||
                        this.initedLabels[
                            dataPointItem.labelKey || dataPointItem.id
                        ];
                    let selected,
                        disabled = false;
                    if (
                        utils.findIndex(
                            this.watchedSettings.fixedColumnDataPoints,
                            item => dataPointItem.id === item
                        ) > -1
                    ) {
                        selected = true;
                        disabled = true;
                    } else {
                        selected = this.saveSelectPoints[item]
                            ? this.saveSelectPoints[item]
                            : false;
                    }

                    const obj = {
                        id: item,
                        title: name,
                        name,
                        selected,
                        disabled,
                        visible: this._isVisibleSettingsDataPoint(name)
                    };
                    list.push(obj);
                });
                categoryObj.list = list;
                categoryObj.showTitle =
                    utils.findIndex(list, item => item.visible) > -1;
                data.push(categoryObj);
            });
            return data;
        },
        handleSelectedPoints() {
            const selectedPoints = [];
            Object.keys(this.saveSelectPoints).forEach(item => {
                if (this.saveSelectPoints[item]) {
                    const dataPointItem = utils.find(
                        this.allDataPoints,
                        dp => item === dp.id
                    );
                    selectedPoints.push({
                        id: item,
                        label:
                            dataPointItem.label ||
                            this.initedLabels[
                                dataPointItem.labelKey || dataPointItem.id
                            ],
                        name:
                            dataPointItem.label ||
                            this.initedLabels[
                                dataPointItem.labelKey || dataPointItem.id
                            ],
                        class: utils.find(
                            this.watchedSettings.fixedColumnDataPoints,
                            dp => dp === item
                        )
                            ? this.namespace('forbid-events')
                            : ''
                    });
                }
            });
            return selectedPoints;
        },
        deselectAllClick() {
            Object.keys(this.saveSelectPoints).forEach(item => {
                if (
                    !utils.find(
                        this.watchedSettings.fixedColumnDataPoints,
                        dp => dp === item
                    )
                ) {
                    delete this.saveSelectPoints[item];
                }
            });
            this.setDataPointsAndModel();
        },
        changePointsColumns(saveDataPoints) {
            this.saveSelectPoints = {};
            saveDataPoints.forEach(dp => {
                this.saveSelectPoints[dp.id] = true;
            });
            this.settingDataPoints = this._getSettingDataPoints();
            this.selectedDataPointsModel = saveDataPoints;
        },
        _isVisibleSettingsDataPoint(name) {
            return this.filterKey
                ? name.toLowerCase().indexOf(this.filterKey.toLowerCase()) > -1
                : true;
        },
        _getGroupKeyItem(key) {
            const groupItem = utils.find(
                this.groupDataPoints,
                f => f.id === key
            );
            if (groupItem) {
                return {
                    groupKey: groupItem.points.join(),
                    groupKeyId: groupItem.id
                };
            }
            return {
                groupKey: this.groupDataPoints[0].points.join(),
                groupKeyId: this.groupDataPoints[0].id
            };
        },
        switchGroupKey(flag) {
            this.watchedSettings.enableGroup = flag;
            const { groupKey, groupKeyId } = this._getGroupKeyItem(
                this.watchedSettings.groupKey
            );
            this.groupKey = groupKey;
            this.groupKeyId = groupKeyId;
            if (!flag) {
                this.groupKey = null;
                this.groupKeyId = null;
            }
            this.changeSettings(this._getStoreSettings());
        },
        changeGroupKey(item) {
            const { groupKey, groupKeyId } = this._getGroupKeyItem(item.id);
            this.groupKey = groupKey;
            this.groupKeyId = groupKeyId;
            this.changeSettings(this._getStoreSettings());
        },
        handleSubscribe(obj) {
            const eventName = 'symbol-update';
            if (obj.removedRows.length) {
                const removeRowId = obj.removedRows.map(rowItem => rowItem.id);
                this.$emit(eventName, {
                    rows: removeRowId,
                    visibleRows: obj.visibleRows.map(rowItem => rowItem.id),
                    action: 'remove'
                });
            }
            if (obj.hidedRows.length) {
                this.$emit(eventName, {
                    rows: obj.hidedRows.map(rowItem => rowItem.id),
                    visibleRows: obj.visibleRows.map(rowItem => rowItem.id),
                    action: 'hide'
                });
            }

            if (obj.addedRows.length) {
                this.$emit('symbol-update', {
                    rows: obj.addedRows.map(rowItem => rowItem.id),
                    visibleRows: obj.visibleRows.map(rowItem => rowItem.id),
                    action: 'add'
                });
            }
        },
        exportRTDClick(event) {
            if (event.target.tagName === 'SPAN') {
                this.exportPopoverVisible = false;
                this.$emit('export-rtd-click');
            }
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.exportPopoverSelected = false;
            this.exportPopoverVisible = false;
            this.$emit('settings-popover-hide');
        },
        handleBackClick() {
            if (this.showSettingsColumns) {
                this.showSettingsColumns = false;
                this.showSettingsPoints = true;
                this.selectedDataPointsModel = this.handleSelectedPoints();
            } else if (this.showSettingsPoints) {
                this.showSettingsPoints = false;
                this.settingsPopoverNavSelected = true;
            } else if (this.exportDownLoadVisible) {
                this.exportDownLoadVisible = false;
                this.exportPopoverSelected = true;
            }
        },
        handleDoneClick() {
            if (this.showSettingsPoints && !this.isGreaterMaxCount) {
                this.showSettingsPoints = false;
                this.showSettingsColumns = true;
                this.selectedDataPointsModel = this.handleSelectedPoints();
            } else if (this.showSettingsColumns) {
                this.settingsPopoverVisible = false;
                this.columnType = COLUMN_SET_TYPES.CUSTOM;
                this.dataPoints = this.selectedDataPointsModel.map(
                    item => item.id
                );
                this.changeColumns({
                    dataPoints: this.dataPoints,
                    id: COLUMN_SET_TYPES.CUSTOM
                });
                this.changeSettings(this._getStoreSettings());
            }
        },
        handleResetClick() {
            const defaultDataPoints = this.watchedSettings.defaultDataPoints
                ? this.watchedSettings.defaultDataPoints.split(',')
                : getDataPoint(COLUMN_SET_TYPES.BASIC);
            this.saveSelectPoints = {};
            defaultDataPoints.forEach(point => {
                this.allDataPoints.forEach(dp => {
                    if (point === dp.id) {
                        this.saveSelectPoints[point] = true;
                    }
                });
            });
            this.setDataPointsAndModel();
        },
        handleCustomDataPointsClick(event) {
            this.toggleSettings(event);
            this.showSettingsPoints = true;
            this.settingsPopoverNavSelected = false;
            this.showDataPointPopover();
        },
        changeSettingsNavGroup(item) {
            this.columnType = item.id;
            this.dataPoints = [];
            this.changeColumns(item);
            this.changeSettings(this._getStoreSettings());
        },
        changeSettingsNavSelected(item) {
            this.changeColumns(item);
            if (item.id !== COLUMN_SET_TYPES.CUSTOM) {
                this.hideSettingsPopover();
                this.columnType = item.id;
                this.dataPoints = [];
                this.changeSettings(this._getStoreSettings());
            } else {
                this.showSettingsPoints = true;
                this.settingsPopoverNavSelected = false;
                this.showDataPointPopover();
                this.$refs.watchlistUiSettings.enableTrapFocus();
            }
        },
        changeColumns(item) {
            const targetColumn = utils.find(
                this.columnSets,
                o => o.id === item.id
            );
            if (
                targetColumn.id === COLUMN_SET_TYPES.CUSTOM &&
                item.dataPoints
            ) {
                targetColumn.dataPoints = item.dataPoints;
            }
            let points = [];
            this.groupDataPoints.forEach(item => {
                points = [...points, ...item.points];
            });
            const groupFields = Array.from(new Set(points));
            const fields = [...targetColumn.dataPoints, ...groupFields];
            this.$emit('column-change', fields);
        },
        showDataPointPopover() {
            this.saveSelectPoints = {};
            this.gridColumns.forEach(element => {
                this.saveSelectPoints[element.id] = true;
            });
            this.setDataPointsAndModel();
        },
        setDataPointsAndModel() {
            this.settingDataPoints = this._getSettingDataPoints();
            this.selectedDataPointsModel = this.handleSelectedPoints();
        },
        _getStoreSettings() {
            return {
                symbol: this.symbol,
                columnType: this.columnType,
                dataPoints: this.dataPoints.join(','),
                enableGroup: this.watchedSettings.enableGroup,
                groupKey: this.groupKeyId
            };
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-watchlist';
.#{$namespace} {
    &-header__bold {
        display: inline-flex;
        align-items: center;
        span {
            margin-left: $mds-space-half-x;
        }
    }
    &-content-header {
        @include mds-body-text-s(true);
        margin: 5px 0;
    }
    &-settings-group-container:first-child {
        .#{$namespace}-content-header {
            margin-top: 0;
        }
    }
    &-settings {
        .mds-section___markets {
            max-height: 450px;
        }
        .markets-ui-settings__content {
            max-height: 250px;
            .markets-ui-dragable-list {
                overflow: initial;
            }
        }
    }
    &-group-custom {
        display: flex;
        align-items: center;
        margin: 0 5px;
        span {
            margin: 0 5px;
            &:nth-of-type(3) {
                margin-right: 12px;
            }
        }
    }
    &-forbid-events {
        pointer-events: none;
        .markets-ui-dragable-list-remove-icon {
            display: none;
        }
    }
    &-group-switch {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        span {
            font-size: 14px;
            font-weight: 400;
        }
    }
    &-content__panel {
        display: flex;
        justify-content: space-between;
        margin-top: $mds-space-2-x;
    }
    &-container-footer {
        @include mds-body-text-s();
    }
    &-content__panel {
        line-height: 26px;
        margin-top: $mds-space-1-x;
    }
    &-content__error {
        color: $mds-feedback-color-error;
        margin-top: $mds-space-1-x;
    }
    &-content__msg-info {
        color: $mds-color-neutral-67;
        margin-top: $mds-space-half-x;
    }
    &-content__reset {
        font-weight: $mds-typography-font-weight-bold;
        color: $mds-color-neutral-50;
        cursor: pointer;
    }
    &-content__greater {
        color: $mds-feedback-color-error;
        font-weight: $mds-typography-font-weight-bold;
    }
    &-export-header {
        font-size: 14px;
        padding: 5px 0;
        border-top: 1px solid $mds-color-neutral-90;
        span {
            font-weight: $mds-typography-font-weight-bold;
            text-decoration: underline;
            vertical-align: middle;
            cursor: pointer;
        }
    }

    &-grid__name-cell {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-navigation-setting {
        padding: $mds-space-1-x 0;
        display: flex;
        justify-content: space-between;
        &-export {
            cursor: pointer;
            padding: 0 $mds-space-1-x;
            outline: none;
            color: $mds-text-color-secondary;
            font-size: $mds-typography-font-size-s;
        }
        &-button-group {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .markets-ui-menus,
    &-button-custom {
        margin-left: $mds-space-1-x;
        height: 26px;
    }

    &-navigation &.markets-ui-container__dark-gray {
        color: $mds-text-color-primary-on-dark;
        &-content__msg-info {
            font-size: $mds-typography-font-size-s;
            color: $mds-color-neutral-95;
        }
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-body {
            height: 100%;
            overflow: auto;
        }
        .#{$namespace}-grid-container {
            height: calc(100% - 42px);
            overflow: auto;
            &__no-menu {
                height: 100%;
            }
        }
    }

    &.markets-ui-container__dark-gray {
        .#{$namespace} {
            &-navigation-setting {
                &-export {
                    color: $mds-text-color-secondary-on-dark;
                    .markets-ui-icon {
                        stroke: $mds-text-color-secondary-on-dark;
                    }
                }
            }
            &-group-custom span {
                color: $mds-text-color-secondary-on-dark;
            }
        }
    }
}
</style>
