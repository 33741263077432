var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading,
        showExport: _vm.watchedSettings.showExport
      },
      on: {
        "setting-click": _vm.toggleSettings,
        "export-click": _vm.toggleExport
      }
    },
    [
      _c(
        "div",
        {
          class: _vm.namespace("body"),
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          _vm.watchedSettings.showExport || _vm.showWebLayout
            ? _c(
                "div",
                {
                  class: [_vm.namespace("navigation-setting")],
                  attrs: {
                    role: "navigation",
                    "aria-label": "Component Settings"
                  }
                },
                [
                  _vm.showWebLayout
                    ? _c(
                        "div",
                        {
                          class: [
                            _vm.namespace("navigation-setting-button-group")
                          ]
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("markets-ui-menus", {
                                attrs: {
                                  dataModel: _vm.settingsNavGroupList,
                                  label: _vm.initedLabels.standard,
                                  skin: _vm.watchedSettings.skin,
                                  "collapse-mode": _vm.menusCollapse
                                },
                                on: { change: _vm.changeSettingsNavGroup }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("markets-ui-button", {
                            class: _vm.namespace("button-custom"),
                            attrs: {
                              "icon-right": "caret-down",
                              text: _vm.customButtonName,
                              variation: "secondary",
                              skin: _vm.watchedSettings.skin,
                              "aria-expanded": _vm.customExpanded
                            },
                            on: { click: _vm.handleCustomDataPointsClick }
                          }),
                          _vm._v(" "),
                          _vm.watchedSettings.showGroup
                            ? _c(
                                "div",
                                { class: _vm.namespace("group-custom") },
                                [
                                  _c(
                                    "div",
                                    { class: _vm.namespace("group-switch") },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.initedLabels.groupView)
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("markets-ui-switch", {
                                        attrs: {
                                          skin: _vm.skin,
                                          checked:
                                            _vm.watchedSettings.enableGroup,
                                          label: _vm.initedLabels.groupView,
                                          value: "volume",
                                          hiddenLabel: ""
                                        },
                                        on: { change: _vm.switchGroupKey }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.watchedSettings.enableGroup
                                    ? _c(
                                        "span",
                                        {
                                          class: _vm.namespace(
                                            "group-custom-text"
                                          )
                                        },
                                        [_vm._v(_vm._s(_vm.initedLabels.by))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.watchedSettings.enableGroup
                                    ? _c("markets-ui-select", {
                                        attrs: {
                                          optional: "",
                                          label:
                                            _vm.initedLabels
                                              .timePeriodUpperCase,
                                          skin: _vm.watchedSettings.skin,
                                          "data-model": _vm.groupKeyList
                                        },
                                        on: { change: _vm.changeGroupKey }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.watchedSettings.showExport && _vm.showWebLayout
                    ? _c("markets-ui-button", {
                        attrs: {
                          skin: _vm.skin,
                          icon: "share",
                          "icon-left": "share",
                          variation: _vm.isMobile ? "icon-only" : "flat",
                          text: _vm.initedLabels.exportButton
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.exportData("excel", "xls")
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              class: [
                _vm.namespace("grid-container"),
                _vm.showWebLayout
                  ? ""
                  : _vm.namespace("grid-container__no-menu")
              ]
            },
            [
              _c("markets-ui-grid", {
                attrs: {
                  skin: _vm.watchedSettings.skin,
                  "group-key": _vm.groupKey,
                  formatter: _vm.formatter,
                  hover: _vm.watchedSettings.gridHover,
                  option: _vm.gridOption,
                  columns: _vm.gridColumns,
                  rows: _vm.dataModel,
                  focusRowId: _vm.activeSymbol,
                  labels: _vm.gridLabels,
                  caption: _vm.initedLabels.watchlistTitle
                },
                on: {
                  "hover-menu-click": function($event) {
                    return _vm.$emit("hover-menu-click", $event)
                  },
                  "visible-rows-update": _vm.handleSubscribe,
                  "row-double-click": function($event) {
                    return _vm.$emit("row-double-click", $event)
                  },
                  "render-complete": function($event) {
                    return _vm.$emit("render-complete", $event)
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "watchlistUiSettings",
          class: _vm.namespace("settings"),
          attrs: {
            slot: "settings",
            showSearchField: _vm.showSettingsPoints,
            showDone: _vm.showDoneSettings,
            showBack: _vm.showBackSettings,
            showReset: _vm.showReset,
            skin: _vm.watchedSettings.skin,
            "triggered-by": _vm.settingsPopoverTriggerId,
            visible: _vm.popoverVisible,
            title: _vm.popoverTitle,
            doneLabel: _vm.doneLabel,
            width: _vm.popoverWidth,
            searchFieldPlaceholder: _vm.initedLabels.search,
            doneDisable: !!_vm.infoMsg
          },
          on: {
            hide: _vm.hideSettingsPopover,
            reset: _vm.handleResetClick,
            back: _vm.handleBackClick,
            done: _vm.handleDoneClick,
            "search-key-changed": _vm.filterDataPoints
          },
          slot: "settings"
        },
        [
          _vm.showColumnSetsList
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNavSelectedList,
                  variation: "selection"
                },
                on: {
                  change: _vm.changeSettingsNavSelected,
                  click: _vm.changeExportFileType
                },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.watchedSettings.showGroup
            ? _c(
                "template",
                { slot: "content" },
                [
                  _c(
                    "div",
                    { class: _vm.namespace("group-switch") },
                    [
                      _vm.isPopoverTypeSettings
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.initedLabels.groupView))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isPopoverTypeSettings
                        ? _c("markets-ui-switch", {
                            attrs: {
                              skin: _vm.skin,
                              checked: _vm.watchedSettings.enableGroup,
                              label: _vm.initedLabels.groupView,
                              value: "volume",
                              hiddenLabel: ""
                            },
                            on: { change: _vm.switchGroupKey }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.watchedSettings.enableGroup && _vm.isPopoverTypeSettings
                    ? _c("markets-ui-select", {
                        attrs: {
                          optional: "",
                          skin: _vm.watchedSettings.skin,
                          label: _vm.initedLabels.timePeriodUpperCase,
                          "data-model": _vm.groupKeyList
                        },
                        on: { change: _vm.changeGroupKey }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettingsPoints
            ? _c(
                "template",
                { slot: "content" },
                _vm._l(_vm.settingDataPoints, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: _vm.namespace("settings-group-container")
                    },
                    [
                      item.showTitle
                        ? _c(
                            "div",
                            { class: _vm.namespace("content-header") },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(item.title) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("markets-ui-checkbox-group", {
                        attrs: {
                          size: "small",
                          skin: _vm.watchedSettings.skin,
                          dataModel: item.list
                        },
                        on: { change: _vm.changeSettingDataPoints }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSettingsColumns
            ? _c(
                "template",
                { slot: "content" },
                [
                  _c("DRAGABLELIST", {
                    attrs: {
                      slot: "content",
                      dataModel: _vm.selectedDataPointsModel,
                      skin: _vm.watchedSettings.skin,
                      labels: _vm.dragableLabels
                    },
                    on: { change: _vm.changePointsColumns },
                    slot: "content"
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.exportDownLoadVisible
            ? _c("template", { slot: "content" }, [
                _c("div", { class: _vm.namespace("export-header") }, [
                  _c("div", [_vm._v(_vm._s(_vm.initedLabels.exportRtdLabel))]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.exportInfoLabel) },
                    on: {
                      click: function($event) {
                        return _vm.exportRTDClick($event)
                      }
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "footer",
            {
              class: _vm.namespace("container-footer"),
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.showSettingsPoints
                ? _c("div", [
                    _vm.infoMsg
                      ? _c(
                          "div",
                          { class: [_vm.namespace("content__error")] },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.infoMsg) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.namespace("content__panel") },
                      [
                        _c(
                          "span",
                          {
                            class: [
                              _vm.namespace("content__count"),
                              _vm.isGreaterMaxCount
                                ? _vm.namespace("content__greater")
                                : ""
                            ]
                          },
                          [_vm._v(_vm._s(_vm.dataPointCount))]
                        ),
                        _vm._v(" "),
                        _c("markets-ui-button", {
                          class: _vm.namespace("content__reset"),
                          attrs: {
                            variation: "flat",
                            skin: _vm.watchedSettings.skin,
                            text: _vm.initedLabels.deselect
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.deselectAllClick($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.showSettingsColumns
                ? _c("div", [
                    _c("div", { class: [_vm.namespace("content__msg-info")] }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.initedLabels.dragInfoMsg) +
                          "\n                "
                      )
                    ])
                  ])
                : _vm._e()
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }