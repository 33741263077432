import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;

export const COLUMN_SET_TYPES = {
    BASIC: 'basic',
    RETURNS: 'returns',
    FUNDAMENTALS: 'fundamentals',
    COMPOSITION_REGION: 'compositionAndRegion',
    MORNINGSTAR_SECTOR: 'morningstarSector',
    GICS_SECTOR: 'gicsSector',
    FIXED_INCOME: 'fixedIncome',
    CUSTOM: 'custom'
};

export const COLUMN_SET_BEHAVIOUR = {
    OVERRIDE: 'override',
    MERGE: 'merge'
};

export function hasPermission(permissions, dataPoint) {
    return (
        utils.findIndex(
            permissions,
            item => item.code === dataPoint && item.value === '1'
        ) > -1
    );
}

export const DEFAULT_COLUMN_SET = [
    {
        id: COLUMN_SET_TYPES.BASIC,
        dataPoints: [
            'name',
            'symbol',
            'lastPrice',
            'chg',
            'chg%',
            'highPrice',
            'lowPrice',
            'volume',
            'tradeDate',
            'tradeTime',
            'timezoneAbbreviation'
        ]
    },
    {
        id: COLUMN_SET_TYPES.RETURNS,
        dataPoints: [
            'name',
            'symbol',
            'returnEndDate',
            'ytdReturn',
            'oneDayReturn',
            'oneWeekReturn',
            'oneMonthReturn',
            'threeMonthReturn',
            'sixMonthReturn',
            'oneYearReturn',
            'twoYearReturn',
            'threeYearReturn',
            'fiveYearReturn',
            'tenYearReturn',
            'fifteenYearReturn'
        ]
    },
    {
        id: COLUMN_SET_TYPES.FUNDAMENTALS,
        dataPoints: [
            'name',
            'symbol',
            'lastPrice',
            'chg',
            'chg%',
            'volume',
            'week52HighPrice',
            'week52LowPrice',
            'marketCap',
            'PE',
            'dividendYieldCurrent',
            'lastDividend',
            'lastDividendDate',
            'tradeDate',
            'tradeTime',
            'timezoneAbbreviation'
        ]
    },
    {
        id: COLUMN_SET_TYPES.COMPOSITION_REGION,
        dataPoints: [
            'name',
            'symbol',
            'usEquityComposition',
            'nonUSEquityComposition',
            'fixedIncomeComposition',
            'otherComposition',
            'cashComposition',
            'equityRegionNotClassifiedPercentage',
            'northAmericaPercentage',
            'unitedKingdomPercentage',
            'europeDevelopedPercentage',
            'europeEmergingPercentage',
            'africaMiddleEastPercentage',
            'japanPercentage',
            'australasiaPercentage',
            'asiaDevelopedPercentage',
            'asiaEmergingPercentage',
            'latinAmericaPercentage',
            'portfolioDate'
        ]
    },
    {
        id: COLUMN_SET_TYPES.MORNINGSTAR_SECTOR,
        dataPoints: [
            'name',
            'symbol',
            'basicMaterials',
            'consumerCyclical',
            'financialServices',
            'realEstate',
            'communicationServices',
            'energy',
            'industrials',
            'technology',
            'consumerDefensive',
            'healthcare',
            'utilities',
            'portfolioDate'
        ]
    },
    {
        id: COLUMN_SET_TYPES.GICS_SECTOR,
        license: ['GICS'],
        dataPoints: [
            'name',
            'symbol',
            'gicsEnergy',
            'gicsMaterials',
            'gicsIndustrials',
            'gicsConsumerDiscretionary',
            'gicsConsumerStaples',
            'gicsHealthcare',
            'gicsFinancials',
            'gicsInformationTechnology',
            'gicsCommunicationServices',
            'gicsUtilities',
            'gicsRealEstate',
            'portfolioDate'
        ]
    },
    {
        id: COLUMN_SET_TYPES.FIXED_INCOME,
        dataPoints: [
            'name',
            'symbol',
            'effectiveDuration',
            'effectiveMaturity',
            'averageCreditQualityName',
            'creditQualityAaa',
            'creditQualityAa',
            'creditQualityA',
            'creditQualityBbb',
            'creditQualityBb',
            'creditQualityB',
            'creditQualityBelowB',
            'creditQualityNotRated',
            'government',
            'corporate',
            'securitized',
            'municipal',
            'cashAndEquivalents',
            'derivative',
            'ttMYield',
            'secYield',
            'portfolioDate'
        ]
    }
];

export function getDataPoint(id) {
    const columnSet = utils.find(DEFAULT_COLUMN_SET, item => {
        return item.id === id;
    });
    if (!columnSet) {
        return [];
    }
    return columnSet.dataPoints;
}

export function getColumnSetsByLicense(columnSets, Permission = []) {
    const checkColumnSetLicense = columnSet => {
        if (!Permission.length) {
            return false;
        } else {
            return columnSet.license.some(f => hasPermission(Permission, f));
        }
    };
    return columnSets.filter(item => {
        const find = utils.find(DEFAULT_COLUMN_SET, c => c.id === item.id);
        if (find) {
            item.license = find.license;
        }
        return !item.license || checkColumnSetLicense(item);
    });
}

export function mergeColumnSets(columnSet) {
    const ret = [];
    const index = {};
    DEFAULT_COLUMN_SET.forEach((c, idx) => {
        ret.push({
            id: c.id,
            dataPoints: c.dataPoints
        });
        index[c.id] = idx;
    });
    columnSet.forEach(c => {
        const idx = index[c.id];
        if (idx >= 0) {
            ret[idx].dataPoints = c.dataPoints;
        } else {
            ret.push(c);
        }
    });
    return ret;
}
