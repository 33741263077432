<template>
    <WATCHLISTUI
        v-if="licenseStatus"
        ref="UI"
        :skin="skin"
        :showLoading="showLoading"
        :dataModel="dataModel"
        :symbol="symbolStr"
        :removable="removable"
        :activeSymbol="activeSymbol"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        :userPermission="userPermission"
        @track="trackEvent"
        @column-change="changeColumns"
        @export-rtd-click="exportRTDClick"
        @hover-menu-click="clickHoverMenu"
        @render-complete="renderComplete"
        @settings-changed="changeSettings"
        @settings-popover-hide="hideSettingsPopover"
        @symbol-update="updateSymbols"
        @row-double-click="doubleClick"
        @export-click="exportClick"
        @export-popover-hide="hideExportPopover"
    >
    </WATCHLISTUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import WATCHLISTUI from './watchlist-ui';
import labels from './assets/labels.json';
import {
    DEFAULT_COLUMN_SET,
    COLUMN_SET_BEHAVIOUR
} from './metadata/columnSets';
import { DataPoints, getDataPointsByDataType } from './metadata/dataPoint';
const { utils, mixins } = mwcMarketsCore;

export default {
    name: 'mwc-markets-watchlist',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: {
        symbol: {
            type: String
        },
        removable: {
            type: Boolean
        },
        customRows: { type: [String, Array] },
        updateGroupFunc: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            defaultConfig: {
                settings: {
                    title: 'Watchlist',
                    layout: '', // decide which layout will be used, default is table. it can be set as mbg
                    autoHeight: true, // only work for mbg layout
                    groupKey: null,
                    showHeader: false,
                    showSetting: true,
                    showExport: false,
                    showExcelRTD: false, // don't need output this for individual component on dev-site. It is only for Horizon.
                    showBorder: false,
                    updateLastAccessTime: true,
                    removable: false,
                    focusable: false,
                    languageId: 'en-US',
                    sortable: true,
                    frozenColumn: 0,
                    frozenRow: -1,
                    skin: '',
                    columnType: 'basic',
                    columnSets: DEFAULT_COLUMN_SET,
                    columnSetsBehaviour: COLUMN_SET_BEHAVIOUR.OVERRIDE,
                    symbol: '',
                    dataPoints: '', // it is used to select the data point in custom set or saved data points
                    defaultDataPoints: '', // it is initial passing data points for user to reset columns, take attention to open it.
                    maxCount: 23, // data point max count
                    customRows: [], // it is used to extend the rows. See below example
                    /*[{
                        IPODate: '2013-04-12',
                        id: 'F00000OPW6',
                        name: 'FlexShares Intl Qual Div ETF',
                        portfolioWeight: 20.1,
                        secId: 'F00000OPW6',
                        symbol: 'IQDF'
                    },
                    {
                        IPODate: '2012-12-14',
                        id: 'F00000OPW3',
                        name: 'FlexShares Quality Dividend ETF',
                        portfolioWeight: 30.4,
                        secId: 'F00000OPW3',
                        symbol: 'QDF'
                    }]*/
                    customColumns: [], // it is used to extend the columns. See below example
                    /*[{
                        id: 'keyClassification1',
                        align: 'left',
                        dataType: 'string',
                        category: 'proprietaryData',
                        label: 'Key Classification 1'
                    },
                    {
                        id: 'keyClassification2',
                        align: 'left',
                        dataType: 'string',
                        category: 'proprietaryData',
                        label: 'Key Classification 2'
                    }] */
                    customGroupDataPoints: [], // it is used to extend the groups. See below example
                    /*[{
                        id: 'keyClassificationDefault',
                        label: 'Key Classification',
                        points: [
                            'keyClassification1',
                            'keyClassification2',
                            'keyClassification3'
                        ]
                    },
                    {
                        id: 'keyClassificationRegion1',
                        label: 'Key Classification - Region 1',
                        points: ['keyClassification1', 'region', 'keyMarketCap']
                    }]*/
                    rowsPerPage: 20,
                    rowsPerPageList: [10, 20, 50],
                    showPageInfo: true,
                    showPageSelect: true,
                    showGroup: true, //control show group with key.
                    enableGroup: false, //control group key switch
                    updateGroupFunc: null, // provide a function to handle group. See below example
                    /* function(groupRows) {
                        groupRows.forEach(item => {
                            if (item.subs) {
                                item.portfolioWeight = item.subs.reduce((d, c) => {
                                    return d + c.portfolioWeight;
                                }, 0);
                                item.subs.forEach(d => {
                                    if (!d.subs) {
                                        d.keyWeight03 =
                                            (d.portfolioWeight /
                                                item.portfolioWeight) *
                                            100;
                                    } else {
                                        d.subs.forEach(f => {
                                            f.keyWeight02 =
                                                (f.portfolioWeight /
                                                    item.portfolioWeight) *
                                                100;
                                        });
                                    }
                                });
                            }
                        });
                    }*/
                    fixedColumnDataPoints: [], // for control need fixed column data point. example: ['symbol', 'name']
                    stickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 2,
                            minWidth: 0,
                            maxWidth: 500
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 4,
                            minWidth: 500,
                            maxWidth: 640
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 6,
                            minWidth: 640,
                            maxWidth: 960
                        }
                    ]
                },
                labels: labels,
                format: {
                    integer: {
                        dataType: 'number',
                        useGrouping: true
                    },
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    time: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    }
                },
                intlNamespace: 'mwc-markets-watchlist'
            },
            dataModel: [],
            showLoading: false,
            symbolList: [],
            activeSymbol: '',
            fields: [],
            userPermission: [],
            licenseStatus: null,
            customRowsData: []
        };
    },
    computed: {
        symbolStr() {
            return this.symbolList.join(',');
        }
    },
    watch: {
        symbol(val) {
            this.changeSymbolList(val);
        },
        licenseStatus(value) {
            this.$nextTick(() => {
                this._visibilityObserver();
            });
        },
        customRows(val) {
            let data = val;
            // when value incoming from customElement the value type is String.
            if (typeof data === 'string') {
                data = JSON.parse(val);
            }
            this.customRowsData = data;
        },
        updateGroupFunc(val) {
            this.settings.updateGroupFunc = val;
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(labels);
        this.allDataPoints = [...DataPoints, ...this.settings.customColumns];
        this.numberDataPoints = getDataPointsByDataType(
            {
                int: true,
                number: true,
                upDown: true,
                upDownWithPercent: true,
                numberWithPercent: true,
                portfolioWithPercent: true
            },
            this.allDataPoints
        );
        this.currentDataType = null;
        this.symbolArray = this.settings.symbol
            ? this.settings.symbol.split(',')
            : [];
        this.addSymbolStr = null;
        this.currentSecurities = null;
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.initialized = false;
        this.targetVisibility = false;
        this.changeDataType(this.settings.dataType);
        this.mktdata.queryPermission({ fields: '16384' }).then(permission => {
            this.licenseStatus = true;
            if (permission.userFunctions) {
                this.userPermission = permission.userFunctions;
            }
        });
        this.customRowsData = this.settings.customRows;
    },
    mounted() {},
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        if (this.isSubscribing) {
            this._unsubscribe(this.currentDataType, {
                rows: this.currentSecurities
            });
            this.isSubscribing = false;
        }
    },
    methods: {
        initialize() {
            this.initialized = true;
            if (this.symbolArray.length) {
                this.changeSymbolList(
                    utils.isArray(this.symbolArray)
                        ? this.symbolArray.join(',')
                        : ''
                );
            }
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._subscribe(this.currentDataType, {
                        rows: this.currentSecurities
                    });
                }
            } else {
                if (!this.targetVisibility) {
                    if (this.isSubscribing) {
                        this._unsubscribe(this.currentDataType, {
                            rows: this.currentSecurities
                        });
                        this.isSubscribing = false;
                    }
                }
                this.targetVisibility = false;
            }
        },
        exposeMethods() {
            return [
                'toggleSettings',
                'toggleExport',
                'prependSymbol',
                'appendSymbol'
            ];
        },
        _visibilityObserver() {
            utils.visibilityObserver.observe(
                this.$el,
                this._visibilityObserverCallback
            );
            if (!utils.isHidden(this.$el) && !this.initialized) {
                this.initialize();
            }
        },
        prependSymbol(val) {
            this.addSymbolData(val, false);
        },
        appendSymbol(val) {
            this.addSymbolData(val, true);
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        changeDataType(value) {
            const oldValue = this.currentDataType;
            this._unsubscribe(oldValue, {
                rows: this.currentSecurities
            });
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (
                !this[`${this.currentDataType}SubscribePromise`] &&
                this.mktdata
            ) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            this._subscribe(this.currentDataType, {
                rows: this.currentSecurities
            });
        },
        addSymbolData(symbol, type) {
            if (!symbol || !this.mktdata) {
                return;
            }
            this.showLoading = true;
            const addSymbolList = symbol.split(',');
            this.getWatchlistSymbolIdService(addSymbolList)
                .then(res => {
                    let { rows, symbolList } = res;
                    const newSymbolList = symbolList.filter(symbol => {
                        return !utils.find(this.symbolList, symbol);
                    });
                    rows = rows.filter(r => utils.find(newSymbolList, r.id));
                    return { rows, newSymbolList, symbolList };
                })
                .then(res => {
                    const { rows, newSymbolList, symbolList } = res;
                    if (type) {
                        this.symbolList = [
                            ...this.symbolList,
                            ...newSymbolList
                        ];
                    } else {
                        this.symbolList = [
                            ...newSymbolList,
                            ...this.symbolList
                        ];
                    }
                    if (!newSymbolList.length) {
                        this.activeSymbol = symbolList[symbolList.length - 1];
                    } else {
                        this.addSymbolStr =
                            newSymbolList[newSymbolList.length - 1];
                    }
                    return this.getStaticQuoteData(rows, newSymbolList);
                })
                .then(dataModel => {
                    const addData = dataModel.filter(
                        d =>
                            !utils.find(
                                this.dataModel,
                                item =>
                                    item.id === d.id ||
                                    item.securityId === d.id ||
                                    item.quoteInstrument === d.id
                            )
                    );
                    if (type) {
                        this.dataModel = [...this.dataModel, ...addData];
                    } else {
                        this.dataModel = [...addData, ...this.dataModel];
                    }
                    this.showLoading = false;
                })
                .catch(e => {
                    this.showLoading = false;
                });
        },
        changeSymbolList(symbol = '') {
            if (!symbol || !this.mktdata) {
                this.symbolList = [];
                this.dataModel = [];
                return;
            }
            this.showLoading = true;
            this.getWatchlistSymbolIdService(symbol.split(','), 'set')
                .then(res => {
                    const { rows, symbolList } = res;
                    this.symbolList = symbolList;
                    return this.getStaticQuoteData(rows, symbolList);
                })
                .then(dataModel => {
                    this.dataModel = dataModel;
                    if (this.customRowsData.length) {
                        const CustomRowsData = utils.extend(
                            true,
                            [],
                            this.customRowsData
                        );
                        CustomRowsData.forEach(rows => {
                            // customRow may use securityId or instrument as the id.
                            const idx = utils.findIndex(
                                this.dataModel,
                                item =>
                                    item.id === rows.id ||
                                    item.securityId === rows.id ||
                                    item.quoteInstrument === rows.id ||
                                    item.queryKey === rows.id
                            );
                            // push special(example: Aggregate) row in the first row
                            if (idx < 0) {
                                this.dataModel.unshift(rows);
                                return;
                            }
                            const rowObj = utils.extend(
                                {},
                                this.dataModel[idx],
                                rows
                            );
                            this.$set(this.dataModel, idx, rowObj);
                        });
                    }
                    this.showLoading = false;
                    this.renderCompleted = true;
                })
                .catch(e => {
                    if (!(e && e.isCanceled)) {
                        this.dataModel = [];
                        this.showLoading = false;
                    }
                });
        },
        exportRTDClick() {
            this.$emit('export-rtd-click');
        },
        renderComplete(e) {
            if (e && this.addSymbolStr) {
                this.activeSymbol = this.addSymbolStr;
            }
        },
        toggleExport(target, sheetName) {
            this.$refs.UI.toggleExport(target, sheetName);
        },
        getWatchlistSymbolIdService(symbolArray, type) {
            this.showLoading = true;

            if (this.idServicePromise && type === 'set') {
                // add symbols cannot cancel the previous one, while set symbols need cancel the previous id service
                this.idServicePromise.cancel();
            }

            this.idServicePromise = utils.cancelablePromise(
                this.mktdata.securities(symbolArray)
            );
            return this.idServicePromise.promise.then(resData => {
                const rows = [];
                const symbolList = [];
                resData.forEach(security => {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            security,
                            'mstarType'
                        )
                    ) {
                        // Tracking: evaluate the security types in Watchlist (Number of Stock vs Fund vs ETF..etc..).
                        this.trackEvent({
                            name: 'security-type-select',
                            value: security.mstarType
                        });
                    }
                    const id = this.getSavedSymbol(security);
                    const securityItem = {
                        id,
                        symbol: security.mstarSymbol,
                        queryKey: security.queryKey,
                        name: security.name,
                        currency: security.currency,
                        timezone: security.timezone,
                        region: security.region,
                        strikePrice: security.strikePrice,
                        timezoneAbbreviation: security.timezoneAbbreviation
                    };
                    symbolList.push(id);
                    rows.push(securityItem);
                });
                return { rows, symbolList };
            });
        },
        getStaticQuoteData(rows = [], symbolList = []) {
            if (symbolList.length === 0 || !this.mktdata) {
                return Promise.resolve([]);
            }
            const promiseList = [
                this.mktdata
                    .quotes({
                        securities: symbolList
                    })
                    .catch(() => {
                        return Promise.resolve([]);
                    }),
                this.mktdata
                    .securityDetails({
                        securities: symbolList,
                        fields: this.fields
                    })
                    .catch(() => {
                        return Promise.resolve([]);
                    })
            ];
            return Promise.all(promiseList).then(res => {
                const [quoteAllData, quoteStaticData] = res;
                this.showLoading = false;
                return this.getResultData(rows, quoteAllData, quoteStaticData);
            });
        },
        getResultData(rowData, quoteData, staticData) {
            let rows = rowData.map(row => {
                let obj = {};
                quoteData.forEach(item => {
                    if (row.id === item.security) {
                        obj.dataType = item.dataType;
                        obj = utils.extend(
                            true,
                            obj,
                            this.filterData(item.quotes),
                            row
                        );
                    }
                });
                staticData.forEach(item => {
                    if (row.id === item.security) {
                        obj = utils.extend(
                            true,
                            obj,
                            this.filterData(item.statics),
                            row
                        );
                    }
                });
                // for option special ticker
                obj.extraExchangeInfo =
                    obj.extraExchangeInfo || obj.tradeExchange;
                obj.name = obj.name || obj.contractName;
                return obj;
            });
            return rows;
        },
        removeSymbolData(deleteSymbolList) {
            //deleteSymbolList: [126.1.IBM,126.1.AAPL]
            const symbolList = this.symbolList;
            const dataModel = utils.extend(true, [], this.dataModel);
            this.symbolList = symbolList.filter(item => {
                return !utils.find(deleteSymbolList, item);
            });
            this.dataModel = dataModel.filter(item => {
                return !utils.find(deleteSymbolList, item.id);
            });
        },
        updateSymbols(params) {
            switch (params.action) {
                case 'add':
                    this._subscribe(this.currentDataType, params);
                    break;
                case 'remove':
                    this._unsubscribe(this.currentDataType, params);
                    this.removeSymbolData(params.rows);
                    break;
                case 'hide':
                    this._unsubscribe(this.currentDataType, params);
                    break;
            }
        },
        updateData(security, updates) {
            const idx = utils.findIndex(
                this.dataModel,
                item =>
                    item.id === security ||
                    item.securityId === security ||
                    item.quoteInstrument === security
            );
            if (idx < 0) {
                return;
            }
            const rowObj = utils.extend(
                {},
                this.dataModel[idx],
                this.filterData(updates)
            );
            this.$set(this.dataModel, idx, rowObj);
        },
        _subscribe(dataType, item) {
            if (item.visibleRows) {
                this.currentSecurities = item.visibleRows;
            }
            if (item.rows && item.rows.length) {
                this.isSubscribing = true;
                if (dataType && this[`${dataType}SubscribePromise`]) {
                    this[`${dataType}SubscribePromise`].then(subscriber => {
                        subscriber.subscribe(
                            item.rows,
                            this.subscribeListener,
                            {
                                updateLastAccessTime: this.settings
                                    .updateLastAccessTime
                            }
                        );
                    });
                }
            }
        },
        filterData(quotes) {
            const map = {};
            if (quotes && quotes.length) {
                quotes.forEach(q => {
                    let value = q.value;
                    if (utils.find(this.numberDataPoints, q.name)) {
                        value = +value;
                    }
                    if (q.name === 'name' || q.name === 'symbol') {
                        return;
                    }
                    if (q.name === 'chg') {
                        map['upDown'] = value;
                    }
                    if (q.name === 'volume' && value === 0) {
                        value = null;
                    }
                    map[q.name] = value;
                });
            }
            return map;
        },
        _unsubscribe(dataType, item) {
            if (item.visibleRows) {
                this.currentSecurities = item.visibleRows;
            }
            if (
                dataType &&
                item.rows &&
                item.rows.length &&
                this[`${dataType}SubscribePromise`]
            ) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(
                            item.rows,
                            this.subscribeListener
                        );
                    })
                    .catch(e => {});
            }
        },
        changeColumns(fields) {
            this.fields = fields;
            this._updateStaticData();
        },
        async _updateStaticData() {
            const staticData = await this.mktdata
                .securityDetails({
                    securities: this.symbolList,
                    fields: this.fields
                })
                .catch(() => {
                    return Promise.resolve([]);
                });
            staticData.forEach(item => {
                this.updateData(item.security, item.statics);
            });
        },
        clickHoverMenu({ item, row }) {
            this.exposeSecurity(row.id)
                .then(params => {
                    this.$emit('hover-menu-click', item, params);
                })
                .catch(() => {
                    this.$emit('hover-menu-click', item, row.id);
                });
        },
        doubleClick({ row }) {
            this.trackEvent({
                name: 'row-double-click'
            });
            this.exposeSecurity(row.id)
                .then(params => {
                    this.$emit('row-double-click', params);
                })
                .catch(() => {
                    this.$emit('row-double-click', row.id);
                });
        },
        async exportClick(item, formatter) {
            const date = new Date();
            const fileName = `${
                item.sheetName
            }_${date.getFullYear()}${date.getMonth() + 1}${utils.prefixZero(
                date.getDate()
            )}.${item.suffix}`;
            let resData, params;
            if (item.fileType === 'quote') {
                const symbolArray = this.dataModel.map(item => item.id);
                if (!symbolArray.length) {
                    return;
                }
                params = {
                    symbols: symbolArray.join(','),
                    sheetName: item.sheetName
                };
                resData = await this.mktdata
                    .exportExcelRTD(params)
                    .catch(() => '');
                utils.exportToFile(resData, fileName);
            } else {
                const columnsArray = item.columns.split(',');
                const dataRows = this.dataModel.map(row => {
                    return columnsArray.map(col =>
                        this.dataFormat({ row, col, formatter })
                    );
                });
                let dataStr = '';
                dataRows.forEach(row => {
                    dataStr += `${row.join('|')}||`;
                });
                params = {
                    columns: item.columnsLabel,
                    sheetName: item.sheetName,
                    fileType: item.fileType,
                    data: dataStr
                };
                resData = await this.mktdata
                    .exportWatchlist(params)
                    .catch(() => '');
                utils.exportToFile(resData, fileName);
            }
        },
        dataFormat({ row, col, formatter }) {
            if (row[col] || row[col] === 0) {
                const column =
                    this.allDataPoints.find(dp => dp.id === col) || {};
                const numberType = [
                    'upDown',
                    'upDownWithPercent',
                    'numberWithPercent',
                    'portfolioWithPercent'
                ];
                const isUpDownFormat =
                    utils.inArray(numberType, column.dataType) > -1;
                const obj = {
                    value: row[col],
                    dataType: isUpDownFormat ? 'number' : column.dataType,
                    formatter
                };
                let formatValue = utils.getFormatValue(obj);
                if (isUpDownFormat) {
                    if (
                        formatValue > 0 &&
                        column.dataType !== 'numberWithPercent' &&
                        column.dataType !== 'portfolioWithPercent'
                    ) {
                        formatValue = `+${formatValue}`;
                    }
                    if (
                        column.dataType === 'upDownWithPercent' ||
                        column.dataType === 'numberWithPercent' ||
                        column.dataType === 'portfolioWithPercent'
                    ) {
                        formatValue = `${formatValue}%`;
                    }
                }
                return formatValue;
            } else {
                return '-';
            }
        },
        hideExportPopover() {
            this.$emit('export-popover-hide');
        }
    },
    components: {
        WATCHLISTUI
    }
};
</script>
